<template>
  <el-dialog :title="dialogTitle"
                 width="900px"
                :visible.sync="validVisible"
                :close-on-click-modal="false"
                @close="close"
                :before-close="close"
                class="dialog"
                left>
        <div>
          <el-form ref="form"
                 :rules="rules"
                 :model="publishForm"
                 status-icon
                 label-width="120px"
                 label-position="top"
                 v-loading="loading">
            <el-row>
              <el-col :span="24">
                <el-form-item label="标题" prop="title">
                  <el-input v-model="publishForm.title"
                            placeholder="请输入标题"
                            maxlength="255"
                            :autosize="{ minRows: 1, maxRows: 5}"
                            type="textarea">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="蔬菜种类"
                              prop="vegKindId">
                  <div style="width:100%">
                    <el-select v-model="publishForm.vegKindId" filterable placeholder="请选择" style="width:100%" @change="selectChange">
                      <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.vegSpecificType"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="蔬菜重量(吨)" prop="vegWeight">
                  <el-input v-model="publishForm.vegWeight"
                            placeholder="请输入"
                            maxlength="30"
                            @keyup.native="publishForm.vegWeight = oninput(publishForm.vegWeight,3)">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="价格(元/公斤)" prop="price">
                  <el-input v-model="publishForm.price"
                            placeholder="请输入价格"
                            maxlength="30"
                            @keyup.native="publishForm.price = oninput(publishForm.price,3)">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="手机号" prop="contactNumber">
                  <el-input v-model="publishForm.contactNumber"
                            placeholder="请输入"
                            maxlength="11">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="所在地区"
                              prop="areaCode">
                  <area-select v-model="publishForm.areaCode"
                              placeholder="请选择所在地区"
                              :clearable="true"
                              :maxLevel="3"
                              :showAllLevels="true"
                              :emitPath="false"
                              style="width:100%"></area-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model="publishForm.address"
                            type="textarea"
                            maxlength="255"
                            :autosize="{ minRows: 1, maxRows: 5}"
                            placeholder="请输入详细地址">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="img" v-if="publishType === 0">
              <el-col :span="12">
                <el-form-item label="上传供货信息图"
                              prop="image">
                  <div flex="dir:top cross:center">
                    <el-upload :on-success="onSuccess"
                              :on-remove="onRemove"
                              :file-list="fileList"
                              :headers="headers"
                              list-type="picture-card"
                              action="/admin/sys-file/upload"
                              :disabled="uploaddisabled"
                              :show-file-list="false"
                              :multiple="false"
                              :limit="2"
                              >
                        <div v-if="publishForm.image==''">
                          <i class="el-icon-upload  hasImg"></i>
                          <div class="hasImg1">上传图片</div>
                        </div>
                        <div v-if="publishForm.image!=''">
                          <i class="el-icon-upload hasImg"></i>
                          <div class="hasImg1">上传图片</div>
                          <el-image :src="publishForm.image" style="width:100%;height:150px;"></el-image>
                        </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="上传检测单图"
                              prop="checkImg">
                  <div flex="dir:top cross:center">
                    <el-upload :on-success="onSuccess1"
                                :on-remove="onRemove1"
                                :file-list="fileList1"
                                :headers="headers"
                                list-type="picture-card"
                                action="/admin/sys-file/upload"
                                :disabled="uploaddisabled1"
                                :show-file-list="false"
                                :multiple="false"
                                :limit="2"
                                >
                        <div v-if="publishForm.checkImg==''">
                          <i class="el-icon-upload  hasImg"></i>
                          <div class="hasImg1">上传图片</div>
                        </div>
                        <div v-if="publishForm.checkImg!=''">
                          <i class="el-icon-upload hasImg"></i>
                          <div class="hasImg1">上传图片</div>
                          <el-image :src="publishForm.checkImg" style="width:100%;height:150px;"></el-image>
                        </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注" prop="content">
                  <el-input v-model="publishForm.content"
                            type="textarea"
                            maxlength="512"
                            :autosize="{ minRows: 1, maxRows: 5}"
                            placeholder="请输入">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
              <el-button type="primary" v-loading="loading" :disabled="loading" @click="confirm">提 交</el-button>
              <el-button v-loading="loading" :disabled="loading" @click="close">关 闭</el-button>
            </div>
      </el-dialog>
</template>

<script>
import setting from '@/setting'
import util from '@/libs/util'
import { _GetVegKindList, _AddSupply, _AddNeed } from '@/api/veg'
export default {
  components: {
    AreaSelect: () => import('@/components/area-select/index.vue')
  },
  props: {
    // 发布类型,0:发布供应，1：发布需求
    publishType: {
      type: Number,
      default: 0
    },
    validVisible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    publishType: {
      handler (val) {
        if (val === 0) {
          this.dialogTitle = '新增发布供应信息'
        } else if (val === 1) {
          this.dialogTitle = '新增发布需求信息'
        }
      },
      immediate: true // 初次监听即执行
    },
    validVisible: {
      handler (val) {
        if (val) {
          this.publishForm = {
            address: '',
            areaCode: '',
            checkImg: '',
            contactNumber: '',
            content: '',
            image: '',
            price: 0,
            title: '',
            vegKindId: null,
            vegKindName: '',
            vegWeight: 0
          }
        }
      },
      immediate: true // 初次监听即执行
    }
  },
  data () {
    return {
      loading: false,
      form: {
        current: 1,
        size: 50
      },
      total: 0,

      tableData: [],

      // validVisible: false,
      dialogTitle: '',
      publishForm: {
        address: '',
        areaCode: '',
        checkImg: '',
        contactNumber: '',
        content: '',
        image: '',
        price: 0,
        title: '',
        vegKindId: null,
        vegKindName: '',
        vegWeight: 0
      },
      rules: {
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        vegKindId: [{ required: true, message: '请选择蔬菜种类', trigger: 'blur' }],
        vegWeight: [{ required: true, message: '请输入重量', trigger: 'blur' }],
        price: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        contactNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' },
          { min: 2, max: 11, message: '长度在 2 到 11 个字符', trigger: 'blur' },
          {
            required: true,
            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
            message: '手机号码格式错误',
            trigger: 'blur'
          }],
        areaCode: [{ required: true, message: '请选择所在地区', trigger: 'blur' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        image: [{ required: true, message: '请上传供货图片', trigger: 'blur' }]
      },
      options: [],

      // 上传图片
      fileList: [],
      uploaddisabled: false,
      headers: {
        Authorization: 'Bearer ' + util.getToken().accessToken,
        'TENANT-ID': setting.server.tenantId
      },

      fileList1: [],
      uploaddisabled1: false
    }
  },
  created () {
    this.getVegKind()
  },
  methods: {
    // 获取蔬菜类别列表
    getVegKind () {
      _GetVegKindList().then(res => {
        this.options = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 提交
    confirm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.dialogTitle === '新增发布供应信息') {
            _AddSupply(this.publishForm).then(res => {
              console.log(res)
              this.$message({
                message: '新增发布供应信息成功',
                type: 'success'
              })
              this.loading = false
              this.$bus.$emit('closePublish', true)
            }).catch(err => {
              this.loading = false
              this.$message.error(err)
            })
          } else if (this.dialogTitle === '新增发布需求信息') {
            _AddNeed(this.publishForm).then(res => {
              console.log(res)
              this.$message({
                message: '新增发布需求信息成功',
                type: 'success'
              })
              this.loading = false
              this.$bus.$emit('closePublish', true)
            }).catch(err => {
              this.loading = false
              this.$message.error(err)
            })
          }
        } else {
          // this.loading = false
          this.$message({
            message: '请正确填写信息',
            type: 'warning'
          })
        }
      })
    },
    close () {
      this.$bus.$emit('closePublish', true)
    },
    onSuccess (response, file, fileList) {
      // console.log(response, file, fileList)
      this.fileList = fileList.slice(-1)
      this.publishForm.image = response.data.url
    },

    onRemove (file, fileList) {
      this.publishForm.image = ''
      // console.log('remove', file, fileList)
    },
    onSuccess1 (response, file, fileList) {
      this.fileList1 = fileList.slice(-1)
      this.publishForm.checkImg = response.data.url
      // console.log('success', file)
    },

    onRemove1 (file, fileList) {
      this.publishForm.checkImg = ''
      // console.log('remove', file, fileList)
    },
    // 蔬菜选择修改
    selectChange (val) {
      const that = this
      this.options.forEach(item => {
        if (val === item.id) {
          that.publishForm.vegKindName = item.vegSpecificType
        }
      })
    },
    // 控制充值金额为小数
    oninput (num, limit) {
      return util.oninput(num, limit)
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
    .img{
      border-top: 1px dashed rgba(3,26,18,0.1);
      border-bottom: 1px dashed rgba(3,26,18,0.1);
    }
    .hasImg{
      position: absolute;
      top: 40px;
      left: 200px;
      z-index: 99;
    }
    .hasImg1{
      position: absolute;
      top: 40px;
      left: 185px;
      z-index: 99;
    }
}
</style>
