/*
 * @Description: Vue 入口文件
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: szw
 * @LastEditTime: 2023-01-12 11:24:29
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Element UI
import ElementUI from 'element-ui'
import './element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css'
// 工具类
import '@/libs/util.js'
// Flex 布局
import 'flex.css'
// 重置 CSS 样式
// import 'normalize.css'

// 按钮权限控制
import '@/libs/btn-permissions.js'

// dayjs插件
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart, PieChart, RadarChart, CandlestickChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  LegendComponent,
  ToolboxComponent
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'

import 'lib-flexible/flexible'

import filters from '@/libs/filters'
// 引入datav
import { scrollBoard } from '@jiaminghi/data-view' // 工具类

if (process.env.NODE_ENV !== 'development') { // process是Node环境全部变量, 运行时根据敲击的命令不同, 脚手架会取环境变量给env添加属性和值
  console.log = function () {}
  console.error = function () {}
  console.dir = function () {}
}

// 引用公共 filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  LegendComponent,
  PieChart,
  ToolboxComponent,
  RadarChart,
  CandlestickChart
])
Vue.use(scrollBoard)

// 获取网站配置信息
store.dispatch('setting/getSetting')

dayjs.extend(RelativeTime)

Vue.prototype.$echarts = echarts

Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this // 安装全局事件总线，所有组件都能看到$bus
  }
}).$mount('#app')
