<!--
 * @Description: 菜单组建
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2023-01-04 15:05:22
-->
<template>
  <ul class="menu"
      flex="box:mean">
    <li>
      <router-link to="/index"
                   active-class="active">首页</router-link>
    </li>
    <li>
      <router-link to="/supply"
                   active-class="active">供应大厅</router-link>
    </li>
    <li>
      <router-link to="/need"
                   active-class="active">需求大厅</router-link>
    </li>
    <li>
      <router-link to="/priceQuotations"
                   active-class="active">价格行情</router-link>
    </li>
    <li>
      <router-link to="/information"
                   active-class="active">资讯中心</router-link>
    </li>
    <li>
      <router-link to="/uc/user"
                   active-class="active">用户中心</router-link>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'MenuItem',
  components: {}
}
</script>
<style lang="scss" scoped>
.menu {
  @extend %wrapper;
  padding-left: 0;

  .active {
    color: $color-primary;
    // border-bottom: 3px solid $color-primary;
    background-color: #fff;
    color: #118148 !important;
  }

  li {
    float: left;
    // border-right: 1px solid $color-border-3;
    height: 30px;

    a {
      display: block;
      height: 55px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      color: #fff !important;
      font-weight: 500;
      background-color: #118148;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    a:hover {
      color: #118148 !important;
      background-color: #fff;
      cursor: pointer;
    }
  }

  li:last-child {
    border-right: 0px;
  }
}
</style>
