/*
 * @Description: 新闻接口
 * @Author: Pancras
 * @Date: 2022-07-14 15:46:52
 * @LastEditors: Pancras
 * @LastEditTime: 2022-12-30 15:48:24
 */
import request from '@/libs/axios'

/**
 * 分页查询新闻
 * @param {*} params
 * @returns
 */
export function _GetNewsByPage (params) {
  return request({
    url: '/cms/news/page',
    method: 'get',
    // params
    params: { ...params, siteId: 5 }
  })
}

/**
 * 文章详情
 *
 */
export function _GetInfo (id) {
  return request({
    url: `/cms/news/${id}`,
    method: 'get'
  })
}

/**
 * 查询新闻列表
 * @param {*} params
 * @returns
 */
export function _GetNewsByList (params) {
  return request({
    url: '/cms/news/list',
    method: 'get',
    // params
    params: { ...params, siteId: 5 }
  })
}

/**
 * 列表查询友情链接
 * @param {*} params
 * @returns
 */
export function _GetLinkList (params) {
  return request({
    url: '/cms/cms-link/list',
    method: 'get',
    params: { ...params, siteId: 5 }
  })
}

/**
 * 查询站点
 * @returns
 */
export function _GetSiteInfo () {
  return request({
    url: '/cms/cms-site/5',
    method: 'get'
  })
}
