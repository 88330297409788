<!--
 * @Description: 前台页面布局
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2023-01-05 13:38:54
-->
<template>
  <div>
    <!-- Header 开始 -->
    <header class="header">
      <!-- TopBar 开始 -->
      <div class="top-bar">
        <div flex="box:last"
             class="main">
          <div>
            <!-- <span>欢迎来到{{ siteInfo&&siteInfo.name }}!</span> -->
            <span>欢迎来到英下乡蔬菜现货交易中心!</span>
            <template v-if="info&&info.username">
              <router-link to="/uc">
                <span class="tl-ml">{{ info.name ? info.name : info.username }}</span>
              </router-link>
              <span class="tl-ml">|</span>
              <el-link type="primary"
                       @click="handleLogout"
                       class="login tl-ml tl-mr-10">注销
              </el-link>
            </template>
            <template v-else>
              <el-link type="primary"
                       @click="handleLogin"
                       class="login tl-ml-40 tl-mr-10">登录
              </el-link>
              |
              <el-link type="primary"
                       @click="handleToReg"
                       class="login tl-ml-10 tl-mr-10">免费注册
              </el-link>
            </template>
          </div>
          <div>
            <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="setHome(this);">设为首页</a>
            |
            <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="addFavorite()">收藏本站</a>
            |
            <router-link to="/uc/user"
                         class="seller tl-ml-10">个人中心
            </router-link>
          </div>
        </div>
      </div>
      <!-- TopBar 结束 -->
      <div class="section">
        <div flex="box:justify main:center cross:center"
             class="section-1">
          <div class="logo">
            <a href="/index">
              <img src="/images/logo.png" />
            </a>
          </div>
          <div flex="box:last"
               class="tl-ml-40 search">
            <div>
              <search-item></search-item>
            </div>
            <div class="tl-ml-10">
              <el-button type="primary" @click="publish" style="background-color:#6FB23E;border:none;">发布资源</el-button>
            </div>
          </div>
          <div class="tl-mt-20 tl-ml-80 qr-code"
               flex="dir:top box:last">
            <div flex="box:last">
              <div class="qr-img"
                   flex="dir:top box:last">
                <img :src="setting.webSite.appStewardQcode" />
                <div>{{ setting.webSite.appStewardTitle }}</div>
              </div>
              <div class="qr-img"
                   flex="dir:top box:last">
                <img :src="setting.webSite.appLaddieQrCode" />
                <div>{{ setting.webSite.appLaddieTitle }}</div>
              </div>
            </div>
            <div class="description">扫码了解更多蔬菜交易信息</div>
          </div>
        </div>
        <nav class="section-2">
          <menu-item></menu-item>
        </nav>
      </div>
    </header>
    <!-- Header 结束 -->
    <main class="main">
      <router-view></router-view>
    </main>
    <footer class="tl-mt-30 footer">
      <footer-item></footer-item>
    </footer>
    <!-- 发布资源弹窗 -->
    <publish-resource :validVisible="dialogPublishVisible" :publishType="publishType"></publish-resource>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import setting from '@/setting'
import FooterItem from './components/FooterItem'
import SearchItem from './components/SearchItem'
import MenuItem from './components/MenuItem'
import PublishResource from './components/publishResource'

export default {
  name: 'FrontLayout',
  components: {
    FooterItem,
    SearchItem,
    MenuItem,
    PublishResource
  },
  data () {
    return {
      setting,
      dialogPublishVisible: false,
      publishType: 0
    }
  },
  mounted () {
    this.$bus.$on('closePublish', data => {
      if (data) {
        this.dialogPublishVisible = false
      }
    })
  },
  computed: {
    // 获取网站信息
    ...mapState('setting', [
      'siteInfo'
    ]),
    // 获取当前登陆用户信息
    ...mapState('user', [
      'info'
    ])
  },
  methods: {
    ...mapActions('account', [
      'logout'
    ]),

    /**
     * @name: handleLogin
     * @description: 登录
     */
    handleLogin () {
      this.$router.push('/login')
    },
    /**
     * 注册
     */
    handleToReg () {
      this.$router.push('/login?to=reg')
    },

    /**
     * @name: handleLogout
     * @description: 登出
     */
    handleLogout () {
      this.logout({
        vm: this,
        confirm: true
      })
    },
    // 加入收藏
    addFavorite () {
      const title = this.siteInfo.name
      const url = window.location.href
      try {
        window.external.addFavorite(url, title)
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, '')
        } catch (e) {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请进入新网站后使用Ctrl+D进行添加')
        }
      }
    },
    // 设置首页
    setHome (obj) {
      const url = window.location.origin
      try {
        obj.style.behavior = 'url(#default#homepage)'
        obj.setHomePage(url)
      } catch (e) {
        if (window.netscape) {
          try {
            netscape.security.PrivilegeManager.enablePrivilege('UniversalXPConnect')
          } catch (e) {
            alert("抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'")
          }
        } else {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【' + url + '】设置为首页。')
        }
      }
    },
    // 发布资源
    publish () {
      if (this.info && this.info.username) {
        // 已登录
        this.$confirm('确认进行发布资源', {
          distinguishCancelAndClose: true,
          confirmButtonText: '前往发布供应信息',
          cancelButtonText: '前往发布需求信息',
          center: true
        }).then(() => {
          this.dialogPublishVisible = true
          this.publishType = 0
        }).catch(action => {
          if (action === 'cancel') {
            this.dialogPublishVisible = true
            this.publishType = 1
          }
        })
      } else {
        // 未登录
        this.$confirm('您尚未登录，是否前往登录页?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push('login').catch(() => {
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      }
    }
  }

}
</script>

<style lang="scss">
.el-message-box__wrapper {
  .el-message-box__btns {
    .el-button {
      border-radius: 4px;

      &:hover {
        border-color: $color-primary;
      }

      &:first-child {
        &:hover {
          color: $color-primary;
          background: #ffffff;
        }

        &:active {
          color: #000000;
          background: #ffffff;
        }
      }
    }

    .el-button--primary {
      background: $color-primary;
    }
  }
}
</style>

<style lang="scss">
// 注册主题
@import "~@/assets/style/layout/front.scss";
</style>
