/*
 * @Description: 系统配置文件
 * @Author: Pancras
 * @Date: 2019-09-29 10:44:30
 * @LastEditors: szw
 * @LastEditTime: 2023-01-04 15:14:24
 */
export default {
  webSite: {
    // title: '阿瓦提县长绒棉现货交易中心',
    logo: '',
    name: '英下蔬菜现货交易中心',
    // copyright: 'Copyright ©2022 阿瓦提县长绒棉现货交易中心 版权所有',
    support: '新疆绿洲驼铃农业发展有限公司',
    // phone: '155 9976 2720',
    // email: 'MXBV@sina.cn',
    officeHours: '周一至周五 10:00-20:00',
    h5QrCodeTitle: '公众号',
    h5QrCodeUrl: 'http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png',
    wxQrCodeTitle: '客服微信',
    wxQrCodeUrl: 'http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png',
    appStewardTitle: '公众号',
    appStewardQcode: 'http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/38d2f35305aa4f45912c523db65c2344.png',
    appLaddieTitle: '客服微信',
    appLaddieQrCode: 'http://assp.lztl.cn/admin/sys-file/lztl-mall/2022/5/71c4f8b278b742978806f283f2878103.png'
    // address: '新疆阿克苏地区阿瓦提县北京路555号'
  },
  // 服务器配置
  server: {
    // 定义 BASE_URL
    baseUrl: 'http://vege.lztl.cn',
    // baseUrl: 'http://192.168.31.26:9999',
    // baseUrl: 'http://183.133.32.138:9816',

    // 加密用 key 应和服务器保持一致
    encryptionKey: 'asspasspasspassp',
    // 租户ID
    tenantId: '7',
    // 认证中心加密字符串，应和数据库配置的的客户端保持一致
    authorization: 'Basic YXdhdDphd2F0'
  }
}
