/*
 * @Description: 系统工具类
 * @Author: Pancras
 * @Date: 2019-10-21 10:13:41
 * @LastEditors: szw
 * @LastEditTime: 2022-12-22 10:06:22
 */
import store from '@/store/index'
import cookies from './util.cookies'
import sessions from './util.sessions'
import security from './util.security'
import db from './util.db'
import dayjs from 'dayjs'

import UpdateLocale from 'dayjs/plugin/updateLocale'
dayjs.extend(UpdateLocale)

const util = {
  cookies,
  sessions,
  db,
  security
}
/**
 * getToken
 * @description 从 sessions 中获取用户 token
 * @return {Object}   返回用户 Token
 * @example 调用示例
 *  let token = util.getToken()
 */
util.getToken = () => {
  let token
  try {
    token = JSON.parse(util.cookies.get('token'))
  } catch (ex) {
    token = undefined
  }
  return token
}

/**
 * getExpiresOn
 * @description  根据 expires_in 获取过期时间
 * @return {Number} expiresOn 过期日期与'1970/01/01'之间的毫秒值
 * @example 调用示例
 *  let token = util.getExpiresOn()
 */
util.getExpiresOn = (expiresIn) => {
  const date = new Date()// 过期时间设为当前时间
  const expiresOn = date.getTime()// 转化为时间戳毫秒数
  return expiresOn + 1000 * expiresIn
}

/**
 * isNull
 * @description 自动识别对象类型并判断是否为空
 * @param {Object} val 需要判断的对象
 * @return {Boolean}   返回 true 或 false
 * @example 调用示例
 *  let isNull = util.isNull(user)
 */
util.isNull = (val) => {
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val ===
      undefined || val === '') {
      return true
    }
    return false
  }
  return false
}

/**
 * looseEqual
 * @description 判断值是否相等(宽松)
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.looseEqual = (a, b) => {
  const isObjectA = util.isObject(a)
  const isObjectB = util.isObject(b)
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b)
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b)
  } else {
    return false
  }
}

/**
 * isObject
 * @description 判断是否是对象
 * @param { Object}  obj
 * @return {Boolean}
 */
util.isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

/**
 * arrayEquals
 * @description 判断数组是否相等
 * @param {Array}    arrayA
 * @param {Array}    arrayB
 * @return {Boolean}
 */
util.arrayEquals = (arrayA, arrayB) => {
  arrayA = arrayA || []
  arrayB = arrayB || []
  if (arrayA.length !== arrayB.length) {
    return false
  }
  for (let i = 0; i < arrayA.length; i++) {
    if (!util.looseEqual(arrayA[i], arrayB[i])) {
      return false
    }
  }
  return true
}

/**
 * isEqual
 * @description 判断值是否相等
 * @param {Objec}  value1
 * @param {Objec}  value2
 * @return {Boolean}
 */
util.isEqual = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return util.arrayEquals(value1, value2)
  }
  return util.looseEqual(value1, value2)
}

/**
 * title
 * @description 从环境变量中获取并设置标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || 'ACIA'
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
}

/**
 * randomLenNum
 * @description 根据长度及日期生成随机数字,系统登录模块使用
 * @param {Int}     随机数长度
 * @param {Date}    日期
 * @return {String} 随机数
 */
util.randomLenNum = (len, date) => {
  let random = ''
  random = Math.ceil(Math.random() * 10000000000000)
    .toString()
    .substr(0, typeof len === 'number' ? len : 4)
  if (date) random = random + Date.now()
  return random
}

/**
 * toCamel
 * @description  下划线命名转换驼峰命名
 * @param {String}  name
 * @return {String} 驼峰命名
 * @example 调用示例
 *  let dictLabel = util.toCamel(name)
 */
util.toCamel = function (name) {
  return name.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
  })
}

/**
 * pathToCamel
 * @description  路由转换转换驼峰命名
 * @param {String}  name
 * @return {String} 驼峰命名
 * @example 调用示例
 *  let dictLabel = util.toCamel(name)
 */
util.pathToCamel = function (name) {
  name = name.replaceAll('/', '_')
  return util.toCamel(name)
}

/**
 * toUnderLine
 * @description  驼峰命名转换下划线命名
 * @param {String}  name
 * @return {String} 下划线命名
 * @example 调用示例
 *  let dictLabel = util.toUnderLine(name)
 */
util.toUnderLine = function (name) {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
 * open
 * @description 根据 url 自动生成 <a> 标签并模拟点击打开页面
 * @param {String} url 地址
 */
util.open = function (url) {
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'd2admin-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('d2admin-link-temp'))
}

/**
 * getDictLabel
 * @description  根据类别及值从系统数据字典中获取中文名称
 * @param {String}  字典值
 * @param {String}  字典类别
 * @return {String} 字典的中文名称
 * @example 调用示例
 *  let dictLabel = util.getDictLabel(value,type)
 */
util.getDictLabel = (value, type) => {
  let label = ''
  if (!util.isNull(value)) {
    const dict = store.getters['dict/' + type]
    const underLineType = util.toUnderLine(type)
    // 数组获取 label
    if (Array.isArray(value)) {
      value.forEach(arrValue => {
        dict.forEach((item) => {
          if (util.looseEqual(item.value, arrValue.toString()) &&
            util.looseEqual(item.type, underLineType)) {
            label = label ? label + ',' + item.label : item.label
          }
        })
      })
    } else {
      // 字符串获取 label
      dict.forEach((item) => {
        if (util.looseEqual(item.value, value) &&
          util.looseEqual(item.type, underLineType)) {
          label = item.label
        }
      })
    }
  }
  return label
}

/**
 * getPolygonCenter
 * @description  获取不规则面的中心点
 * @param {String}  面坐标点数组
 * @return {Array} 中心点坐标
 * @example 调用示例
 *  let dictLabel = util.getPolygonCenter([[x,y],[x,y],[x,y]])
 */
util.getPolygonCenter = (pointList) => {
  let area = 0
  let x = 0
  let y = 0
  for (let i = 1; i <= pointList.length; i++) {
    const lat = pointList[i % pointList.length].lat
    const lng = pointList[i % pointList.length].lng
    const nextLat = pointList[i - 1].lat
    const nextLng = pointList[i - 1].lng
    const temp = (lat * nextLng - lng * nextLat) / 2
    area += temp
    x += (temp * (lat + nextLat)) / 3
    y += (temp * (lng + nextLng)) / 3
  }
  x = x / area
  y = y / area
  const params = {
    lat: x,
    lng: y
  }
  return params
}

util.initday = (date) => {
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in%s',
      past: '%s前',
      s: '秒',
      m: '1分钟',
      mm: '%d分钟',
      h: '1小时',
      hh: '%d小时',
      d: '一天',
      dd: '%d天',
      M: '一个月',
      MM: '%d月',
      y: '一年',
      yy: '%d年'
    }
  })
  const pushDate = dayjs(date).fromNow()
  return pushDate
}

/**
 * oninput
 * @description  控制输入值保留2位小数
 * @param {String}  输入值
 * @param {String}  保留的小数个数
 * @return {String} 处理以后的返回值
 * @example 调用示例
 *  let dictLabel = util.oninput(num, limit)
 */

util.oninput = (num, limit) => {
  let str = num.toString()
  const len1 = str.substr(0, 1)
  const len2 = str.substr(1, 1)
  // 如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 === 0 && len2 !== '.') {
    str = str.substr(1, 1)
  }
  // 第一位不能是.
  if (len1 === '.') {
    str = ''
  }
  // 限制只能输入一个小数点
  if (str.indexOf('.') !== -1) {
    const str_ = str.substr(str.indexOf('.') + 1)
    if (str_.indexOf('.') !== -1) {
      str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
    }
  }
  // 正则替换
  str = str.replace(/[^\d.\d]+/g, '') // 保留数字和小数点
  if (limit / 1 === 1) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, '$1') // 小数点后只能输 1 位
  } else {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1') // 小数点后只能输 2 位
  }

  return str
}

/**
 * oninput
 * @description  控制输入值保留2位小数
 * @param {String}  输入值
 * @param {String}  保留的小数个数
 * @param {String}  输入的最大值
 * @return {String} 处理以后的返回值
 * @example 调用示例
 *  let dictLabel = util.oninput(num, limit)
 */

util.oninput = (num, limit, max) => {
  let str = num.toString()
  const len1 = str.substr(0, 1)
  const len2 = str.substr(1, 1)
  // 如果第一位是0，第二位不是点，就用数字把点替换掉
  if (str.length > 1 && len1 === 0 && len2 !== '.') {
    str = str.substr(1, 1)
  }
  // 第一位不能是.
  if (len1 === '.') {
    str = ''
  }
  // 限制只能输入一个小数点
  if (str.indexOf('.') !== -1) {
    const str_ = str.substr(str.indexOf('.') + 1)
    if (str_.indexOf('.') !== -1) {
      str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
    }
  }
  // 正则替换
  str = str.replace(/[^\d.\d]+/g, '') // 保留数字和小数点
  if (limit / 1 === 1) {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,1})?.*$/, '$1') // 小数点后只能输 1 位
  } else {
    str = str.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1') // 小数点后只能输 2 位
  }

  // 控制值大于0小于特定值
  if (parseFloat(str) < 0) {
    str = 0
  } else if (parseFloat(str) > max) {
    str = max
  }
  return str
}

export default util
