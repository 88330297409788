/*
 * @Description: 用一句话描述文件的作用
 * @Author: szw
 * @Date: 2022-12-22 09:04:17
 * @LastEditors: szw
 * @LastEditTime: 2022-12-22 15:45:22
 */
import request from '@/libs/axios'

/**
 * _GetUserRoles
 * @description 获取蔬菜类别
 */
export function _GetVegKindList () {
  return request({
    url: '/veg-trading/veg-kind/all/list',
    method: 'get'
  })
}

/**
 * _GetUserRoles
 * @description 获取供应列表(个人)
 */
export function _GetPersonSupplyList (params) {
  return request({
    url: '/veg-trading/veg-supply-info/user/supply/page',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 获取供应浏览记录
 */
export function _GetSupplyTrackList (params) {
  return request({
    url: '/veg-trading/veg-supply-info/track/supply/page',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 用户新增供应信息
 */
export function _AddSupply (params) {
  return request({
    url: '/veg-trading/veg-supply-info/add/supply',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 用户编辑供应信息
 */
export function _EditSupply (params) {
  return request({
    url: '/veg-trading/veg-supply-info/change/supply',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 提交供应发布审核-取消发布
 */
export function _SupplySubmitOrCancel (params) {
  return request({
    url: '/veg-trading/veg-supply-info/publish/supply',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 删除供货信息
 */
export function _DelSupply (params) {
  return request({
    url: '/veg-trading/veg-supply-info/delete/supply',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 获取需求列表(个人)
 */
export function _GetPersonNeedList (params) {
  return request({
    url: '/veg-trading/veg-demand-info/user/demand/page',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 获取需求浏览记录
 */
export function _GetNeedTrackList (params) {
  return request({
    url: '/veg-trading/veg-demand-info/track/demand/page',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 用户新增需求信息
 */
export function _AddNeed (params) {
  return request({
    url: '/veg-trading/veg-demand-info/add/demand',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 用户编辑需求信息
 */
export function _EditNeed (params) {
  return request({
    url: '/veg-trading/veg-demand-info/change/demand',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 提交需求发布审核-取消发布
 */
export function _NeedSubmitOrCancel (params) {
  return request({
    url: '/veg-trading/veg-demand-info/publish/demand',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 删除需求信息
 */
export function _DelNeed (params) {
  return request({
    url: '/veg-trading/veg-demand-info/delete/demand',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 获取蔬菜价格列表
 */
export function _GetVegPriceList (params) {
  return request({
    url: '/veg-trading/veg-price/price/page',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 获取蔬菜价格曲线
 */
export function _GetVegPriceLine (params) {
  return request({
    url: '/veg-trading/veg-price/price/trend',
    method: 'get',
    params: { ...params }
  })
}

/**
 * _GetUserRoles
 * @description 取消收藏
 */
export function _CancelFavorite (params) {
  return request({
    url: '/veg-trading/veg-collect/remove/collect',
    method: 'post',
    data: params
  })
}

/**
 * _GetUserRoles
 * @description 删除足迹
 */
export function _DelTrack (params) {
  return request({
    url: '/veg-trading/veg-track/delete/track',
    method: 'post',
    data: params
  })
}
