<!--
 * @Description:搜索组件
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2023-01-03 10:26:31
-->
<template>
  <el-form style="width:530px"
           :model="form">
    <el-input :placeholder="`请输入${form.searchType==='1'?'供应':form.searchType==='2'? '需求': ''}查询`"
              v-model="form.value"
              @keyup.enter.native="searchList"
              class="input-with-select">
      <el-select v-model="form.searchType"
                 slot="prepend"
                 placeholder="">
        <el-option v-for="item in options"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
        </el-option>
      </el-select>
      <el-button slot="append"
                 @click="searchList">查询</el-button>
    </el-input>
  </el-form>
</template>

<script>
export default {
  name: 'SearchItem',
  components: {},
  data () {
    return {
      options: [{
        value: '1',
        label: '供应'
      }, {
        value: '2',
        label: '需求'
      }],
      form: {
        searchType: '1',
        value: ''
      }
    }
  },
  methods: {
    searchList () {
      if (this.form.value !== '') {
        if (this.form.searchType === '1') {
          this.$router.push(`/supply/?q=${this.form.value}`)
        } else if (this.form.searchType === '2') {
          this.$router.push(`/need/?q=${this.form.value}`)
        }
      }
    }
  }
}

</script>
<style lang="scss">
.el-button {
  // border-radius: 0;
  margin: 0 auto;
}

li.el-select-dropdown__item.selected {
  color: $color-primary;
}

.input-with-select {
  // width: 480px;

  .el-input-group__prepend {
    width: 45px;
    border: 2px solid $color-primary;
    background-color: transparent;
    border-radius: 0;
    color: #fff;
  }

  .el-input__inner {
    border: 2px solid $color-primary;
    border-right: 0px;
    border-left: 0px;

    &:focus {
      border-color: $color-primary;
    }
  }

  .el-input-group__append {
    width: 50px;
    color: #fff;
    border-radius: 0px;
    border: 1px solid $color-primary;
    background-color: $color-primary;
    padding: 0 10px;
  }
}
</style>
