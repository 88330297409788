<!--
 * @Description: 用户中心布局
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2022-12-23 16:36:47
-->
<template>
  <div>
    <!-- Header 开始 -->
    <header class="header">
      <!-- TopBar 开始 -->
      <div class="top-bar">
        <div flex="box:last"
             class="main">
          <div>
            <!-- <span>欢迎来到{{ siteInfo&&siteInfo.name }}!</span> -->
            <span>欢迎来到英下乡蔬菜现货交易中心!</span>
            <template v-if="info&&info.username">
              <router-link to="/uc">
                <span class="tl-ml">{{ info.name ? info.name : info.username }}</span>
              </router-link>
              <span class="tl-ml">|</span>
              <el-link type="primary"
                       @click="handleLogout"
                       class="login tl-ml tl-mr-10">注销
              </el-link>
            </template>
            <template v-else>
              <el-link type="primary"
                       @click="handleLogin"
                       class="login tl-ml-40 tl-mr-10">登录
              </el-link>
              |
              <el-link type="primary"
                       @click="handleToReg"
                       class="login tl-ml-10 tl-mr-10">免费注册
              </el-link>
            </template>
          </div>
          <div>
            <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="setHome(this);">设为首页</a>
            |
            <a href="javascript:void(0);"
               class="tl-ml-10 tl-mr-10"
               @click="addFavorite()">收藏本站</a>
            |
            <router-link to="/"
                         class="seller tl-ml">返回首页
            </router-link>
          </div>
        </div>
      </div>
      <!-- TopBar 结束 -->
      <div class="search">
        <div class="main"
             flex="main:justify; cross:center">
          <div class="logo">
            <a href="/index">
              <img src="~@/assets/backend/logo.png"
                   alt="">
            </a>
          </div>
          <div class="title">
            个人中心首页
          </div>
          <!-- <div class="search">
            <el-form :model="form">
              <el-input :placeholder="`请输入${form.searchType==='1'?'供应':form.searchType==='2'? '需求': ''}查询`"
                        v-model="form.value"
                        @keyup.enter.native="searchList"
                        class="input-with-select">
                <el-select v-model="form.searchType"
                           slot="prepend"
                           placeholder="">
                  <el-option v-for="item in options"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <el-button slot="append"
                           @click="searchList">查询</el-button>
              </el-input>
            </el-form>
          </div> -->
        </div>
      </div>
    </header>
    <div class="main-container tl-mt"
         flex>
      <!-- 左侧区域开始 -->
      <div class="left"
           flex="dir:top">
        <!-- 用户基本信息 -->
        <!-- <user-info-item></user-info-item> -->
        <!-- 用户中心菜单 -->
        <menu-item></menu-item>
      </div>
      <!-- 左侧区域结束 -->
      <!-- 右侧区域开始 -->
      <div class="right"
           flex="dir:top">
        <router-view></router-view>
      </div>
      <!-- 右侧区域结束 -->
    </div>
    <footer class="tl-mt-30 footer">
      <footer-item></footer-item>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FooterItem from '../front/components/FooterItem'
import setting from '@/setting'
import UserInfoItem from './components/UserInfoItem' // 用户基本信息组件
import MenuItem from './components/MenuItem' // 用户中心菜单组件

export default {
  name: 'BackendLayout',
  components: {
    FooterItem,
    UserInfoItem, // 用户基本信息组件
    MenuItem // 用户中心菜单组件
  },
  data () {
    return {
      title: setting.webSite.title,
      options: [{
        value: '1',
        label: '供应'
      }, {
        value: '2',
        label: '需求'
      }],
      form: {
        searchType: '1',
        value: ''
      }
    }
  },
  computed: {
    // 获取网站信息
    ...mapState('setting', [
      'siteInfo'
    ]),
    // 获取当前登陆用户信息
    ...mapState('user', [
      'info'
    ])
  },
  methods: {
    ...mapActions('account', [
      'logout'
    ]),

    /**
     * @name: handleLogin
     * @description: 登录
     */
    handleLogin () {
      this.$router.push('/login')
    },
    /**
     * 注册
     */
    handleToReg () {
      this.$router.push('/login?to=reg')
    },

    /**
     * @name: handleLogout
     * @description: 登出
     */
    handleLogout () {
      this.logout({
        vm: this,
        confirm: true
      })
    },
    // 搜索
    searchList () {
      if (this.form.value !== '') {
        if (this.form.searchType === '1') {
          this.$router.push(`/supply/?q=${this.form.value}`)
        } else if (this.form.searchType === '2') {
          this.$router.push(`/need/?q=${this.form.value}`)
        }
      }
    },
    // 加入收藏
    addFavorite () {
      const title = this.siteInfo.name
      const url = window.location.href
      try {
        window.external.addFavorite(url, title)
      } catch (e) {
        try {
          window.sidebar.addPanel(title, url, '')
        } catch (e) {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n加入收藏失败，请进入新网站后使用Ctrl+D进行添加')
        }
      }
    },
    // 设置首页
    setHome (obj) {
      const url = window.location.origin
      try {
        obj.style.behavior = 'url(#default#homepage)'
        obj.setHomePage(url)
      } catch (e) {
        if (window.netscape) {
          try {
            netscape.security.PrivilegeManager.enablePrivilege('UniversalXPConnect')
          } catch (e) {
            alert("抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'")
          }
        } else {
          alert('抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【' + url + '】设置为首页。')
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/layout/backend.scss";
</style>
