/*
 * @Description: Store：用户信息
 * @Author: Pancras
 * @Date: 2022-06-20 12:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-07-08 17:02:01
 */
import util from '@/libs/util'

export default {

  namespaced: true,

  state: {
    info: {}, // 用户基本信息
    roles: [], // 用户角色
    permissions: [] // 用户权限
  },

  actions: {

    /**
     * @description 设置用户信息
     * @param {Object} context
     * @param {Array} info 用户基本信息
     */
    setInfo ({ state, dispatch }, info) {
      return new Promise(resolve => {
        // store 赋值
        state.info = info
        // 持久化
        dispatch('db/set', {
          dbName: 'sys',
          path: 'user.info',
          value: state.info,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },

    /**
     * @description 设置角色信息
     * @param {Object} context
     * @param {Array} roles 用户角色
     */
    setRoles ({ state }, roles) {
      // store 赋值
      state.roles = roles
      // 使用 session 存储 roles
      util.cookies.set('roles', JSON.stringify(roles))
    },

    /**
    * @description 设置权限信息
    * @param {Object} context
    * @param {Array} permissions 用户权限
    */
    setPermissions ({ state }, permissions) {
      // store 赋值
      state.permissions = permissions
      // 使用 session 存储 permissions
      util.cookies.set('permissions', JSON.stringify(permissions))
    },

    /**
     * 从数据库读取用户信息
     * @param {Object} context
     */
    async load ({ state, dispatch }) {
      // Store 赋值
      state.info = await dispatch('db/get', {
        dbName: 'sys',
        path: 'user.info',
        defaultValue: '',
        user: true
      }, { root: true })
      return state.info
    }
  }
}
