<!--
 * @Description: 用户中心菜单组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: szw
 * @LastEditTime: 2022-12-23 16:24:46
-->
<template>
  <div class="menu-container">
    <div class="title-bar"
         flex="cross:center">
      <div class="l-icon">
        <img src="~@/assets/uc/user.png"
             alt="">
      </div>
      <div class="title">
        个人中心
      </div>
      <!-- <div class="r-icon">
        <router-link to="message">
          <el-badge :value="unreadNum"
                    class="item"
                    type="success">
            <img src="~@/assets/uc/email.png"
                 alt="">
          </el-badge>
        </router-link>
      </div> -->
    </div>
    <div class="nav-container tl-mt-30">
      <el-menu default-active="/uc/user"
               class="el-menu-vertical-demo"
               @select="handleSelect"
               :default-openeds="[1]"
               unique-opened>
        <el-menu-item index="/uc/user">
          <i class="el-icon-user"></i>
          <span>用户资料</span>
        </el-menu-item>
        <el-menu-item index="/uc/safe">
          <i class="el-icon-setting"></i>
          <span>修改密码</span>
        </el-menu-item>
        <el-menu-item index="/uc/mySupply">
          <i class="el-icon-tickets"></i>
          <span>我的供应发布</span>
        </el-menu-item>
        <el-menu-item index="/uc/myNeed">
          <i class="el-icon-tickets"></i>
          <span>我的需求发布</span>
        </el-menu-item>
        <el-submenu index="5">
          <template slot="title">
            <i class="el-icon-star-off"></i>
            <span>我的收藏</span>
          </template>
          <el-menu-item index="/uc/mySupplyFavorite">供应</el-menu-item>
          <el-menu-item index="/uc/myNeedFavorite">需求</el-menu-item>
        </el-submenu>
        <el-submenu index="6">
          <template slot="title">
            <i class="el-icon-refresh-left"></i>
            <span>浏览记录</span>
          </template>
          <el-menu-item index="/uc/mySupplyHistory">供应</el-menu-item>
          <el-menu-item index="/uc/myNeedHistory">需求</el-menu-item>
        </el-submenu>
      </el-menu>
      <!-- <ul class="nav">
        <li>
          <router-link to="/uc/user">用户资料</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li>
          <router-link to="/uc/safe">修改密码</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li>
          <router-link to="/uc/mySupply">我的供应发布</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li>
          <router-link to="/uc/myNeed">我的需求发布</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li><a href="JavaScript:">我的收藏</a></li>
        <li>
          <router-link to="/uc/mySupplyFavorite">供应信息</router-link>
        </li>
        <li>
          <router-link to="/uc/myNeedFavorite">需求信息</router-link>
        </li>
      </ul>
      <ul class="nav">
        <li><a href="JavaScript:">浏览记录</a></li>
        <li>
          <router-link to="/uc/mySupplyHistory">供应信息</router-link>
        </li>
        <li>
          <router-link to="/uc/myNeedHistory">需求信息</router-link>
        </li>
      </ul> -->

    </div>
  </div>
</template>

<script>
import { _GetUserRoles } from '@/api/user'
import { _GetIndexUnreadMessage } from '@api/userIndex'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'MenuItem',
  data () {
    return {
      unreadNum: 0,
      timer: null,
      roles: null,
      hasRole: true,
      hasRoleZhaHua: false,
      hasRoleFangSha: false
    }
  },
  computed: {
    // 获取当前登陆用户信息
    ...mapState('user', [
      'info'
    ])
  },
  mounted () {
    // 已经登陆获取角色名称
    if (this.info && this.info.username) {
      this.login = true
      this.getUserRoles()
    }
    // this.timer = setInterval(() => {
    //   this.getUnreadMessage()
    // }, 5000)
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    ...mapActions('account', [
      'logout'
    ]),
    // 获取未读信息数量
    getUnreadMessage () {
      _GetIndexUnreadMessage().then(res => {
        this.unreadNum = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 菜单选择跳转
    handleSelect (key, keyPath) {
      this.$router.push({
        path: key
      }).catch(() => { })
    },
    // 获取用户权限信息
    getUserRoles () {
      _GetUserRoles().then(res => {
        if (res.length > 0) {
          this.roles = res
          res.forEach(role => {
            if (role.roleCode === 'ROLE_TRADING_FANGSHA') {
              this.hasRoleFangSha = true
            }
            if (role.roleCode === 'ROLE_TRADING_ZHAHUA') {
              this.hasRoleZhaHua = true
            }
          })
        } else {
          this.hasRole = false
        }
      }).catch(err => {
        this.hasRole = false
        console.log(err)
        this.logout({
          vm: this,
          confirm: false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  background-color: #fff;
  width: 272px;
  min-height: 960px;

  .l-icon {
    width: 20px;
    height: 20px;
  }

  .title-bar {
    padding: 20px 35px;
    margin: 0 auto;
    border-bottom: 1px solid $color-primary;

    .title {
      width: 80px;
      font-size: 20px;
      font-weight: 600;
      color: #031a12;
      margin-left: 10px;
    }

    .r-icon {
      margin-top: 10px;
    }
  }

  .nav-container {
    .nav {
      li {
        position: relative;
        list-style: none;
        height: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #323333;
        line-height: 24px;

        a {
          color: #323333;
        }

        &:first-child {
          font-size: 16px;
          font-weight: bold;
          color: #031a12;
          line-height: 24px;

          &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: -10px;
            width: 6px;
            height: 6px;
            background: $color-primary;
            border-radius: 50%;
          }
        }

        &:not(:first-child) {
          margin-left: 1em;
        }
      }
    }
  }
}
</style>
