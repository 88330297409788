/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 13:30:07
 * @LastEditors: adlj
 * @LastEditTime: 2022-12-23 17:52:32
 */
import FrontLayout from '@/layout/front/index'
import BackendLayout from '@/layout/backend/index'

const routes = [
  {
    path: '/',
    redirect: { name: 'index' },
    component: FrontLayout,
    children: [
      // 首页
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index'),
        meta: {
          title: '首页'
        }
      },
      // 搜索列表
      {
        path: 'searchPage',
        name: 'searchPage',
        component: () => import('@/views/searchPage/index'),
        meta: {
          title: '搜索列表'
        }
      },
      // 搜索列表详情
      {
        path: 'searchDetail/:id',
        name: 'searchDetail',
        component: () => import('@/views/searchPage/detail'),
        meta: {
          title: '搜索详情'
        }
      },
      // 资讯导读
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/information'),
        meta: {
          title: '资讯导读'
        }
      },
      // 资讯详情
      {
        path: 'information/details/:id',
        name: 'information-details',
        component: () => import('@/views/information/details'),
        meta: {
          title: '资讯详情'
        }
      },
      // 资讯-通知公告
      {
        path: 'information/inform',
        name: 'information-inform',
        component: () => import('@/views/information/inform'),
        meta: {
          title: '资讯-通知公告'
        }
      },
      // 资讯-资讯列表
      {
        path: 'information/:id/list',
        name: 'information-list',
        component: () => import('@/views/information/list'),
        meta: {
          title: '资讯列表'
        }
      },
      // 供求大厅
      {
        path: 'supply',
        name: 'supply',
        component: () => import('@/views/supply/index'),
        meta: {
          title: '供求大厅'
        }
      },
      // 需求大厅
      {
        path: 'need',
        name: 'need',
        component: () => import('@/views/need/index'),
        meta: {
          title: '需求大厅'
        }
      },
      // 价格行情
      {
        path: 'priceQuotations',
        name: 'priceQuotations',
        component: () => import('@/views/priceQuotations/index'),
        meta: {
          title: '价格行情'
        }
      }

    ]
  },
  {
    path: '/uc',
    redirect: { name: 'user' },
    component: BackendLayout,
    children: [
      // 首页
      // {
      //   path: 'home',
      //   name: 'home',
      //   component: () => import('@/views/backend/index')
      // },
      // 会员资料
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/backend/userInfo')
      },
      // 修改密码
      {
        path: 'safe',
        name: 'safe',
        component: () => import('@/views/backend/userSafe')
      },
      // 我的供应发布
      {
        path: 'mySupply',
        name: 'mySupply',
        component: () => import('@/views/backend/mySupply')
      },
      // 我的需求发布
      {
        path: 'myNeed',
        name: 'myNeed',
        component: () => import('@/views/backend/myNeed')
      },
      // 手机换绑
      {
        path: 'mobile',
        name: 'mobile',
        component: () => import('@/views/backend/userMobile')
      },
      // 消息列表
      {
        path: 'message',
        name: 'message',
        component: () => import('@/views/backend/userMessage')
      },
      // 我的供应收藏
      {
        path: 'mySupplyFavorite',
        name: 'mySupplyFavorite',
        component: () => import('@/views/backend/mySupplyFavorite')
      },
      // 我的需求收藏
      {
        path: 'myNeedFavorite',
        name: 'myNeedFavorite',
        component: () => import('@/views/backend/myNeedFavorite')
      },
      // 供应浏览记录
      {
        path: 'mySupplyHistory',
        name: 'mySupplyHistory',
        component: () => import('@/views/backend/mySupplyHistory')
      },
      // 需求浏览记录
      {
        path: 'myNeedHistory',
        name: 'myNeedHistory',
        component: () => import('@/views/backend/myNeedHistory')
      },
      // 供应发布
      {
        path: 'publishSupply',
        name: 'publishSupply',
        component: () => import('@/views/backend/publishSupply')
      },
      // 需求发布
      {
        path: 'publishNeed',
        name: 'publishNeed',
        component: () => import('@/views/backend/publishNeed')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '登录'
    }
  }
]

export default routes
