<!--
 * @Description: 用户基本信息组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: adlj
 * @LastEditTime: 2022-07-26 11:40:20
-->
<template>
  <div class="u-i-container"
       flex="dir:top">
    <div class="sign">{{ idCard ? name : '未认证' }}</div>
    <div class="avatar"
         flex="dir:top main:center cross:center">
      <img :src="info.avatar?setting.server.baseUrl + info.avatar:'/images/user.png'">
      <span>{{ info.name  }}</span>
    </div>
    <div class="row">用户名：{{ info.username }}</div>
    <div class="row"
         flex="cross:center">
      <div>安全等级：</div>
      <el-progress :percentage="20"
                   :color="'#118148'"
                   :show-text="false"
                   style="width:48%;"></el-progress>

      <span class="tl-ml-5">较好</span>
    </div>
    <div class="row">用户角色：<span>{{userRole}}</span></div>
    <div class="bottom"
         flex="box:mean">
      <div>
        <div v-if="idCard"
             flex="dir:top cross:center">
          <img src="~@/assets/uc/authen-pass.png"
               width="44px"
               height="44px" />
          <span class="active">已认证</span>
        </div>
        <div v-else
             flex="dir:top cross:center">
          <img src="~@/assets/uc/authen-nopass.png"
               width="44px"
               height="44px" />
          <span>未认证</span>
        </div>
      </div>

      <div>
        <div v-if="iphoneShow"
             flex="dir:top cross:center">
          <img src="~@/assets/uc/bind-phone.png"
               width="44px"
               height="44px" />
          <span class="active">已绑定</span>
        </div>
        <div v-else
             flex="dir:top cross:center">
          <img src="~@/assets/uc/unbind-phone.png"
               width="44px"
               height="44px" />
          <span>未绑定</span>
        </div>
      </div>
      <div flex="dir:top cross:center">
        <img src="~@/assets/uc/unbind-email.png"
             width="44px"
             height="44px" />
        <span>未绑定</span>
      </div>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import { mapState } from 'vuex'
import { _GetUserRoles } from '@/api/user'

export default {
  name: 'UserInfoItem',
  data () {
    return {
      setting,
      name: '已认证',
      roles: null
    }
  },
  computed: {
    // 获取当前登陆用户信息
    ...mapState('user', [
      'info'
    ]),
    idCard () {
      return !!this.info.certificateNumber
    },
    iphoneShow () {
      return !!this.info.phone
    },
    userRole () {
      let roleStr = ''
      this.roles && this.roles.forEach(role => {
        roleStr += role.roleName + '  '
      })
      return roleStr
    }
  },
  created () {
    this.getUserRoles()
  },
  methods: {
    // 获取用户权限信息
    getUserRoles () {
      _GetUserRoles().then(res => {
        this.roles = res
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.u-i-container {
  background-color: #fff;
  position: relative;

  .sign {
    position: absolute;
    right: 0;
    top: 25px;
    width: 63px;
    height: 32px;
    background: $color-primary;
    border-radius: 16px 0px 0px 16px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    box-shadow: 1px 1px 5px 0px $color-primary;
  }

  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 25px auto;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 5px;
    }
  }

  .row {
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    color: #323333;
    line-height: 20px;
    margin-top: 15px;
    margin-left: 24px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $color-primary;
      line-height: 20px;
    }
  }

  .bottom {
    margin: 30px 0 20px;

    span {
      margin-top: 8px;
      height: 13px;
      font-size: 13px;
      font-weight: 400;
      color: #717272;
      line-height: 20px;

      &.active {
        color: $color-primary;
      }
    }
  }
}
</style>
