/*
 * @Description: 路由配置文件
 * @Author: Pancras
 * @Date: 2022-06-27 12:00:56
 * @LastEditors: adlj
 * @LastEditTime: 2022-12-21 16:14:42
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store/index'
// 工具类
import util from '@/libs/util.js'

import { MessageBox } from 'element-ui'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach((to, from, next) => {
  // 获取 Token
  const token = util.getToken()
  // 已登录，用户信息为空，则重新载入用户信息
  if (token && token !== 'undefined' && util.isNull(store.state.user.info)) {
    store.dispatch('user/load').catch((err) => {
      this.$message.error(err.message)
      // 错误,跳转到登陆页面
      store.dispatch('account/logout', { vm: router.app })
    })
  }

  // 判断是否是用户中心页面
  if (to.fullPath.startsWith('/uc')) {
    // Token 为空，说明未登录
    if (token && token !== 'undefined') {
      store.dispatch('user/load')
      // .then(res => {
      //   // 如果用户没有完善信息
      //   if (!res.completed) {
      //     if (to.name !== 'user') {
      //       MessageBox.alert('您的个人信息还未完善，请完善后进行其他操作', '正在跳转完善页面', {
      //         confirmButtonText: '确定',
      //         callback: () => {
      //           // 跳转至信息完善页面
      //           next({ name: 'user' })
      //         }
      //       })
      //     }
      //   }
      // })
      next()
    } else {
      // 如果访问页面是登陆页面,则直接放行
      if (to.path === '/login') {
        next()
      } else {
        // 跳转至登录页面
        next({ name: 'login', query: { redirect: to.fullPath } })
      }
    }
  } else {
    next()
  }
})

router.afterEach(to => {
  // 更改标题
  util.title(to.meta.title)
  window.scrollTo(0, 0)
})

export default router
