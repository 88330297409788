<!--
 * @Description: 页面底部组件
 * @Author: Pancras
 * @Date: 2022-06-27 12:21:45
 * @LastEditors: szw
 * @LastEditTime: 2023-01-12 13:31:13
-->
<template>
  <div flex="dir:top">
    <div class="section-bottom">
      <div class="content tl-mt-40"
           flex="cross:center">
        <div class="right tl-mt"
             flex>
          <div class="icon">
            <img src="@/assets/footer/phone.png"
                 width="24"
                 height="20"
                 alt=""
                 srcset="">
          </div>
          <div flex="dir:top main:left">
            <div class="phone">{{siteInfo&&siteInfo.contactNumber}}</div>
            <div class="time">{{ setting.webSite.officeHours }}</div>
            <div class="desc">仅市话费</div>
          </div>
        </div>
        <div class="left"
             flex="box:mean">
          <h4><a href="#">关于我们</a></h4>
          <h4><a href="#">服务协议</a></h4>
          <h4><a href="#">隐私协议</a></h4>
          <h4><a href="#">规则说明</a></h4>
          <h4><a href="/login">用户注册</a></h4>
        </div>
      </div>
      <!-- <div class="copyright tl-mt">
        Copyright：英下蔬菜现货交易中心©2022{{siteInfo&&siteInfo.name}}版权所有
      </div> -->
      <div class="copyright tl-mt">
        Copyright©2022{{siteInfo.name}}版权所有
      </div>
      <div class="copyright tl-mt">
        技术支持：{{siteInfo.technicalSupport}}
      </div>
    </div>
    <!-- Footer 底部结束 -->
  </div>
</template>

<script>
import setting from '@/setting'
import { mapState } from 'vuex'

export default {
  name: 'FooterItem',
  computed: {
    // 获取网站信息
    ...mapState('setting', [
      'siteInfo'
    ])
  },
  data () {
    return {
      setting
    }
  }
}
</script>
<style lang="scss" scoped>
// Footer 底部样式
.section-bottom {
  background-color: #041f11;
  color: #fff;
  height: 400px;

  .content {
    height: 210px;
    @extend %wrapper;
    @media screen and (max-width: 1200px) {
      width: 95%;
    }
    .left {
      width: 50%;
      h4 {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        line-height: 21px;
      }
      ul {
        list-style: none;
        margin: 20px 0 0;
        padding: 0;
        font-size: 14px;

        li {
          color: #efefef;
          width: 64px;
          height: 32px;
          line-height: 22px;

          a {
            color: #efefef;
            cursor: pointer;

            &:active {
              color: $color-primary;
            }
          }
        }
      }
    }

    .right {
      height: 80px;
      width: 50%;

      .icon {
        padding: 15px;
      }

      .phone {
        font-size: 28px;
        font-weight: bold;
      }

      .time {
        font-size: 18px;
      }

      .desc {
        font-size: 12px;
      }

      .contact-me-btn {
        height: 30px;
        width: 150px;
        background: transparent;
        border: 1px solid #fff;
        text-align: center;
        line-height: 30px;
        font-size: 12px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .copyright {
    height: 22px;
    @extend %wrapper;
    font-size: 14px;
    text-align: center;
  }
}
</style>
